<style lang="scss">
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
}
</style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="lg"
    v-model="showModal"
    :title="is_edit ? 'Archival Update' : 'Archival Create'"
  >
    <div class="row">
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for="">Archival Name <span class="text-danger">*</span></label>
          <input
            type="text"
            name=""
            class="form-control"
            id=""
            v-model="archival.name"
            :class="errors['name'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['name']" class="text-danger">{{
            errors["name"][0]
          }}</span>
        </div>
      </div>
      <div class="col-6" style="padding: 10px;">
        <div class="form-group">
          <label for=""
            >Duration (Years) <span class="text-danger">*</span></label
          >
          <input
            type="number"
            name=""
            class="form-control"
            id=""
            min="1"
            v-model="archival.duration"
            :class="errors['duration'] ? 'border border-danger' : ''"
          />
          <span v-if="errors['duration']" class="text-danger">{{
            errors["duration"][0]
          }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Default Rate <span class="text-danger">*</span></label>
          <input
            type="number"
            min="0"
            v-model="archival.default_rate"
            name=""
            class="form-control"
            id=""
            :class="errors['default_rate'] ? 'border border-danger' : ''"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Additional Rate</label>
          <!-- <input
                    type="number"
                    v-model="additional_rate"
                    name=""
                    class="form-control"
                    id=""
                  /> -->

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  v-model="archival.has_additional_rate"
                  style="height:1rem;"
                  aria-label="Checkbox for following text input"
                />
              </div>
            </div>
            <input
              type="text"
              :disabled="!archival.has_additional_rate"
              v-model="archival.additional_rate"
              class="form-control"
              aria-label="Text input with checkbox"
            />
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="">Tax(%)</label>
          <input
            type="number"
            min="0"
            v-model="archival.tax_percentage"
            name=""
            class="form-control"
            id=""
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="">Commission($)</label>
          <input
            type="number"
            min="0"
            v-model="archival.commission_percentage"
            name=""
            class="form-control"
            id=""
          />
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
        <b-button
          v-if="!is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Save
        </b-button>
        <b-button
          v-if="is_edit"
          @click="save()"
          variant="success"
          size="sm"
          class="float-right mr-5"
        >
          Update
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import { CREATE_ARCHIVAL } from "@/core/services/store/actions.type";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    is_edit: {
      type: Boolean,
      default: false
    },
    archival: {
      type: Object,
      default: () => {
        return {
          name: "",
          id: 0,
          default_rate: 0,
          duration: 0,
          additional_rate: 0,
          commission_percentage: 0,
          tax_percentage: 0,
          errors: [],
          has_additional_rate: false
        };
      }
    }
  },
  data() {
    return {
      showImage: false,
      errors: []
    };
  },

  components: {},
  computed: {
    ...mapGetters([])
  },

  methods: {
    close() {
      this.$emit("closemodal");
    },
    save() {
      this.$store
        .dispatch(CREATE_ARCHIVAL, this.archival)
        .then(() => {
          if (!this.is_edit) {
            this.$toastr.s("Archival successfully created");
          } else {
            this.$toastr.s("Archival successfully updated");
          }
          this.$emit("closemodal");
        })
        .catch(err => {
          this.errors = err;
        });
    }
  }
};
</script>
